import { useEffect } from 'react';

function ViewEditorContent({ data, onlyView }: { data?: string; onlyView?: boolean }) {
  const preventAction = (e: Event) => {
    e.preventDefault();
  };

  useEffect(() => {
    if (onlyView) {
      ['copy', 'cut'].forEach((action) => {
        action && window.addEventListener(action, preventAction);
      });
      return () => {
        ['copy', 'cut'].forEach((action) => {
          action && window.removeEventListener(action, preventAction);
        });
      };
    }
  }, [onlyView]);

  useEffect(() => {
    if (onlyView) {
      const handleContextmenu = (e: any) => {
        e.preventDefault();
      };
      document.addEventListener('contextmenu', handleContextmenu);
      return function cleanup() {
        document.removeEventListener('contextmenu', handleContextmenu);
      };
    }
  }, [onlyView]);
  return (
    <div>
      <style>
        {`
          .editor-content ul {
            list-style-type: disc;
            margin-left: 20px;
          }
          .editor-content ol {
            list-style-type: decimal; 
            margin-left: 20px;
          }
          .editor-content li {
            margin-bottom: 10px;
          }
          .editor-content h1 {
            font-size: 2em; 
            font-weight: bold;
            margin-top: 1em;
            margin-bottom: 0.5em;
          }
          
          .editor-content h2 {
            font-size: 1.75em; 
            font-weight: bold;
            margin-top: 1em;
            margin-bottom: 0.5em;
          }
          
          .editor-content h3 {
            font-size: 1.5em; 
            font-weight: bold;
            margin-top: 1em;
            margin-bottom: 0.5em;
          }
          
          .editor-content p {
            font-size: 1em; 
            margin-top: 0.5em;
            margin-bottom: 0.5em;
          }
          .editor-content img {
            max-width: 100%;
            height: auto;
          }
          .editor-content table {
            border-collapse: collapse;
            width: 100%;
          }

          .editor-content th, .editor-content td {
            border: 1px solid #ddd;
            padding: 8px;
            text-align: left;
          }
          .editor-content .align-block-left {
            float: left;
            margin-right: 15px;
          }
          
          .editor-content .block {
            display: block;
            margin-left: auto;
            margin-right: auto;
          }
          
          .editor-content .align-block-right {
            float: right;
            margin-left: 15px;
          }

          .ck-content {
            padding: 1em 1.5em !important;
            overflow-y: scroll !important;
            max-height: 600px;
            ol, ul {
              padding-inline-start: 1em;
            };
            h2 {
              font-size: 1.75em;
              font-weight: bold;
              margin-top: 1em;
              margin-bottom: 0.5em;
            };
            h3 {
              font-size: 1.5em;
              font-weight: bold;
              margin-top: 1em;
              margin-bottom: 0.5em;
            };
            a {
              color: blue; 
            }
          }
        `}
      </style>
      <div dangerouslySetInnerHTML={{ __html: data ?? '' }} className='editor-content'></div>
    </div>
  );
}

export default ViewEditorContent;
