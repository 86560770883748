import { useQuery } from '@tanstack/react-query';
import { paperService } from 'services';
import { CardPaper } from 'views/Test/components';

type Props = {
  courseId: number;
  paperId: number;
  onTesting?: (paperAssignId: number) => void;
};

const StudentPaperContentView = ({ courseId, paperId, onTesting }: Props) => {
  const { data: papers } = useQuery(
    ['paperService.fetchMyPapers'],
    () => paperService.fetchMyPapers({ id: paperId, courseId: courseId, size: -1 }),
    {
      keepPreviousData: true,
    },
  );

  return <>{papers && papers.list.length > 0 && <CardPaper paper={papers.list[0]} onTesting={onTesting} />}</>;
};

export default StudentPaperContentView;
